import * as React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";

// import tochi from "../images/tochi.jpg";
import tochi from "../images/tochukwu.png";
import { Link } from "gatsby";

const Wrapper = styled.div``;

const PageLayout = styled.div`
  min-height: 70vh;
  width: 65%;
  margin: 0 auto;
  margin-top: 7rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1100px) {
    width: 75%;
  }

  @media screen and (max-width: 1075px) {
    flex-direction: column;
  }

  @media screen and (max-width: 600px) {
    width: 85%;
  }
`;

const Text = styled.div``;

const ImageWrapper = styled.div`
  margin-left: 3rem;
`;

const ImageBox = styled.div`
  width: 25rem;
  height: 25rem;
  border: 1px solid white;
  border-radius: 50%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  filter: grayscale(100%);
`;

const Heading = styled.div`
  margin-bottom: 3rem;
`;

const HeadingMain = styled.h3`
  font-size: 2.2rem;
  text-align: center;
  text-decoration: underline;
`;

const Main = styled.main``;

const MainContent = styled.p`
  font-weight: 300;
  line-height: 2.7rem;
  font-size: 1.6rem;
`;

const OutLink = styled.a`
  color: white;
`;

const AboutPage = () => {
  return (
    <Layout>
      <Wrapper>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Tochukwu Nwanguma - About</title>
          <meta
            name="description"
            content="Tochukwu Nwanguma is a top-rated software engineer based in Lagos. With expertise in frontend, backend, he brings business ideas to life with cutting-edge technologies."
          />
          <meta
            name="keywords"
            content="frontend engineer, software engineer, fullstack engineer, engineers in Lagos, fintech engineer, Tochukwu Nwanguma, Lagos developer, Lagos software engineer, fintech solutions, business technology"
          />
          <link rel="canonical" href="https://www.tochukwunwanguma.com/about" />
        </Helmet>
        <PageLayout>
          <Text>
            <Heading>
              <HeadingMain>About me</HeadingMain>
            </Heading>
            <Main>
              <MainContent>
                "Let this be our little secret, this lad right here can do
                literally everything, I love to learn and I do this really well.
                Now we could end this here, but I'd rather tell you about my
                current preoccupations.
                <br></br>
                <br></br>
                Some of my work daily involves building UI and functionality
                with React (NextJS), I have a firm grasp of JavaScript ES6, can
                center divs & do a bunch of other cool things with CSS/SCSS,
                Styled-Components and Tailwind CSS (my current favourite). I
                have built the frontend of applications as the sole frontend
                engineer e.g at{" "}
                <OutLink href="https://cico.ng" target="_blank">
                  CICO
                </OutLink>{" "}
                and{" "}
                <OutLink href="https://kukusend.com" target="_blank">
                  Kukusend
                </OutLink>{" "}
                where I helped build the first versions of the respective
                platforms. I also have experience building the backend of
                applications with NodeJS. I recently led the checkouts team at{" "}
                <OutLink href="https://fincra.com" target="_blank">
                  Fincra
                </OutLink>{" "}
                and I helped build the{" "}
                <OutLink href="https://b2beematch.com" target="_blank">
                  B2beematch
                </OutLink>{" "}
                platform.<br></br>
                <br></br> When I'm not saving the world from bad code or
                name-dropping programming languages & frameworks, I read, go
                indie-alternative band hunting on Youtube or Spotify, play
                tennis or watch Arsenal FC try not to lose. I write about
                transitioning to tech{" "}
                <OutLink href="https://nwanguma.medium.com" target="_blank">
                  here
                </OutLink>
                <br></br>
                <br></br>
                Like what you see or not? want to hear more? email me{" "}
                <OutLink href="mailto:nwangumat@gmail.com" target="_blank">
                  here
                </OutLink>
                "
              </MainContent>
            </Main>
          </Text>
          <ImageWrapper>
            <ImageBox>
              <Image src={tochi} alt="" />
            </ImageBox>
          </ImageWrapper>
        </PageLayout>
      </Wrapper>
    </Layout>
  );
};

export default AboutPage;
